import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Hero from '../components/hero'
import Features1 from '../components/features1'
import CTA from '../components/cta'
import Features2 from '../components/features2'
import Steps from '../components/steps'
import Testimonial from '../components/testimonial'
import Contact from '../components/contact'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Rreth Nesh</title>
      </Helmet>
      <Hero
        text={
          <Fragment>
            <span className="home-text10 thq-heading-1">
              <br></br>
              <span>Industria Ndërtimore e Kosovës</span>
            </span>
          </Fragment>
        }
        text1={
          <Fragment>
            <span className="home-text13 thq-body-large">
              <br></br>
              <span>
                Industria Ndërtimore e Kosovës - INK SHPK, është një kompani
                lidere në industrinë e ndërtimit në Kosovë, e njohur për
                cilësinë e lartë të punimeve dhe përkushtimin ndaj përfundimit
                të projekteve brenda afateve të përcaktuara. Kompania jonë është
                e përkushtuar në ofrimin e zgjidhjeve inovatore dhe të
                qëndrueshme në sektorin e ndërtimit, duke u mbështetur në
                përvojën tonë të gjatë dhe stafin e kualifikuar.
              </span>
            </span>
          </Fragment>
        }
      ></Hero>
      <span>
        <span className="home-text17">Historia dhe Evolucioni</span>
        <br></br>
      </span>
      <Features1
        feature1Title="Nga FINCO Group S"
        feature1Description="E themeluar fillimisht si “FINCO Group S”, kompania jonë ka kaluar nëpër një fazë të rëndësishme të transformimit."
        feature2Title='Në “Industria Ndërtimore e Kosovës"'
        feature2Description1={
          <Fragment>
            <span className="home-feature2-description thq-body-small">
              <span>
                Duke ndryshuar emrin në “Industria Ndërtimore e Kosovës”. Ky
                ndryshim reflekton rritjen dhe zhvillimin e kompanisë, si dhe
                përkushtimin tonë për të qenë në avangardë të industrisë së
                ndërtimit në Kosovë.
              </span>
              <br></br>
            </span>
          </Fragment>
        }
      ></Features1>
      <CTA
        text={
          <Fragment>
            <span className="home-text21 thq-heading-2">
              <span>Projektet Kryesore dhe Partneritetet</span>
              <br></br>
            </span>
          </Fragment>
        }
        content1="Në vitin 2020, INK SHPK filloi punimet në kompleksin “THERANDA E RE” në Shirokë, Suharekë. Ndërtesa I-rë e këtij kompleksi u përfundua me sukses brenda 18 muajve nga fillimi i ndërtimit, duke i dorëzuar çelësat klientëve në kohë rekord. Po në vitin 2020, filluan punimet për Ndërtesën II të kompleksit “THERANDA E RE” dhe bashkëpunimi me “Indigo SH.P.K” për ndërtimin e “AMI Residence”, një kompleks me katër ndërtesa."
      ></CTA>
      <span>
        <span className="home-text25">Projektet e ardhshme!</span>
        <br></br>
      </span>
      <Features2
        feature1Title='"Theranda Center"'
        feature2Title='"Theranda Premium"'
        feature3Title='Kompleksi "Hisari"'
      ></Features2>
      <Steps

        

      ></Steps>
      <Testimonial
        heading1="Testimonials"
        content1="Lexoni se çfarë kanë për të thënë klientët tanë për përvojën e tyre duke punuar me ne."
      ></Testimonial>
      <Contact
        content2="Ekipi ynë është në dispozicion  për t'ju ndihmuar."
        heading1="Na kontaktoni"
        content1="Presim me kënaqësi të marrim mendimin tuaj. Mos ngurroni të na kontaktoni për çdo pyetje ose për të diskutuar projektin tuaj të ndërtimit."
        phone1="+383 49 736 776"
        address={
          <Fragment>
            <span className="home-address thq-body-small">
              <span>rr. Brigada 123, Lagjja Shirokë, Suharekë</span>
              <br></br>
              <span>Objekti I - Theranda e Re</span>
              <br></br>
            </span>
          </Fragment>
        }
        email1="industriaks@gmail.com"
        content3="Ju gjithashtu mund të plotësoni formularin e kontaktit më poshtë dhe ne do t'ju kontaktojmë sa më shpejt të jetë e mundur."
        content4="Follow us on social media for updates and project showcases."
      ></Contact>
    </div>
  )
}

export default Home
