import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './cta.css'

const CTA = (props) => {
  return (
    <div className="thq-section-padding">
      <div className="thq-section-max-width">
        <div className="cta-accent2-bg">
          <div className="cta-accent1-bg">
            <div className="cta-container2">
              <div className="cta-content">
                <span>
                  {props.text ?? (
                    <Fragment>
                      <span className="cta-text3 thq-heading-2">
                        <span>About Us</span>
                      </span>
                    </Fragment>
                  )}
                </span>
                <p className="thq-body-large">{props.content1}</p>
              </div>
              <div className="cta-actions"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

CTA.defaultProps = {
  content1:
    'We are a leading construction company dedicated to delivering high-quality projects on time and within budget. With years of experience in the industry, we have built a strong reputation for excellence and customer satisfaction.',
  text: undefined,
}

CTA.propTypes = {
  content1: PropTypes.string,
  text: PropTypes.element,
}

export default CTA
