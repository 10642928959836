import React from 'react'

import PropTypes from 'prop-types'

import './steps.css'

const Steps = (props) => {
  return (
    <div className="steps-container1 thq-section-padding">
      <div className="steps-max-width thq-section-max-width">
        <div className="steps-container2 thq-grid-2">
          <div className="steps-section-header">
            <h2 className="thq-heading-2">Procesi i blerjes së banesës</h2>
            <p className="thq-body-large">
              Profesionistët tanë, nga fillimi e deri në fund do të ju japin
              përkrahje gjatë gjithë procesit. Nga faza fillestare, e deri tek
              dorëzimi i çelsave së baneses tek ju.
            </p>
          </div>
          <div className="steps-container3">
            <div className="steps-container4 thq-card">
              <h2 className="thq-heading-2">{props.step1Title}</h2>
              <span className="steps-text13 thq-body-small">
                {props.step1Description}
              </span>
              <label className="steps-text14 thq-heading-3">01</label>
            </div>
            <div className="steps-container5 thq-card">
              <h2 className="thq-heading-2">{props.step2Title}</h2>
              <span className="steps-text16 thq-body-small">
                {props.step2Description}
              </span>
              <label className="steps-text17 thq-heading-3">02</label>
            </div>
            <div className="steps-container6 thq-card">
              <h2 className="thq-heading-2">{props.step3Title}</h2>
              <span className="steps-text19 thq-body-small">
                {props.step3Description}
              </span>
              <label className="steps-text20 thq-heading-3">03</label>
            </div>
            <div className="steps-container7 thq-card">
              <h2 className="thq-heading-2">{props.step4Title}</h2>
              <span className="steps-text22 thq-body-small">
                {props.step4Description}
              </span>
              <label className="steps-text23 thq-heading-3">04</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Steps.defaultProps = {
  step1Title: 'Initial Consultation',
  step1Description:
    'We schedule a meeting to discuss your construction needs, budget, and timeline.',

  step2Title: 'Design Phase',
  step2Description:
    'Our team works with you to create a customized design plan that meets your requirements.',

  step3Title: 'Construction Process',
  step3Description:
    'We begin the construction process, keeping you informed every step of the way.',

  step4Title: 'Final Inspection',
  step4Description:
    'Once the project is completed, we conduct a final inspection to ensure everything meets our high standards.',
}

Steps.propTypes = {
  step1Title: PropTypes.string,
  step1Description: PropTypes.string,

  step2Title: PropTypes.string,
  step2Description: PropTypes.string,

  step3Title: PropTypes.string,
  step3Description: PropTypes.string,

  step4Title: PropTypes.string,
  step4Description: PropTypes.string
}

export default Steps
