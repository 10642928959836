import React from 'react';
import ReactDOM from 'react-dom';

import './style.css';
import Home from './views/home';

const App = () => {
  return <Home />;
};

// Ensure the DOM is fully loaded before rendering
document.addEventListener('DOMContentLoaded', function() {
  const rootElement = document.getElementById('react-root');
  if (rootElement) {
    ReactDOM.render(<App />, rootElement);
  } else {
    console.error('React root element not found');
  }
});
